/*******************************
         Site Overrides
*******************************/

.pageNav {
    margin-bottom: 45px !important;
}

.errorMsg {
    color: red;
    font-weight: bold;
    margin-left: 4px;
}

.paddedBox {
    background: #fbfbfb;
    border: 1px solid #d6d6d6;
}

.paddedForm {
    margin: 40px;
}

.paddedForm .formInput {
    margin-bottom: 10px;
}

.paddedForm .formInput input {
    text-align: center !important;
}

.paddedForm .formButton {
    text-align: center;
    margin-top: 10px;
}

.center {
    text-align: center;
}

h4.addSub {
    color: #d3d3d3;
}

.ui.text.menu a.item.addSub {
    margin-bottom: 5px;
    color: teal;
}
.ui.text.menu a.item.addSub:hover {
    color: #004d4d;
}
.ui.text.menu a.item.addSub:active {
    color: #009f9f;
}


/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
    background-color: white;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}


@media only screen and (max-width: 767px) {
    .ui.stackable.buttons {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .ui.stackable.buttons > .button {
        width: 100%;
    }

}
